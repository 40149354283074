import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    breakpoint: Number
  }
  
  static targets = [ "steps", "columnsContainer", "loadingIndicator" ]
  static classes = [ "vertical", "columns", "hidden" ]
  
  connect() {
    this.belowThreshold = false
    this.checkWidthAndAdjustIfNecessary()
    this.hideProgressBarAndUnhideColumnsContainer()
  }
  
  checkWidthAndAdjustIfNecessary() {
    // TODO: Fix this so that it uses its outer div.
    const newWidth = window.innerWidth
    const nowBelowThreshold = (newWidth < this.breakpointValue)
    
    if (this.belowThreshold != nowBelowThreshold) {
      this.belowThreshold = nowBelowThreshold
      
      if (nowBelowThreshold) {
        this.verticalize()
      } else {
        this.unverticalize()
      }
    }
  }
  
  verticalize() {
    this.columnsContainerTarget.classList.add(this.columnsClass)
    
    for (const stepsTarget of this.stepsTargets) {
      stepsTarget.classList.add(this.verticalClass)
    }
  }
  
  unverticalize() {
    this.columnsContainerTarget.classList.remove(this.columnsClass)
    
    for (const stepsTarget of this.stepsTargets) {
      stepsTarget.classList.remove(this.verticalClass)
    }
  }
  
  hideProgressBarAndUnhideColumnsContainer() {
    this.loadingIndicatorTarget.classList.add(this.hiddenClass)
    this.columnsContainerTarget.classList.remove(this.hiddenClass)
  }
}
